import { ReactNode } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Variables } from "../variables";

interface ButtonProps {
    text: string,
    href: string,
    isPrimary?: boolean,
    icon?: ReactNode,
    style?: React.CSSProperties
}

const ButtonLinkElement = styled(Link)`
display: inline-block;
  background-color: var(--light-teal);
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  color: var(--dark-teal);
  text-decoration: none;
  font-size: 1.5rem;
  font-family: var(--slab-font);

  &.primary{
    background-color: var(--medium-teal);
    color: white;
    font-weight: bold;
    text-shadow: 0 0 2px var(--medium-teal-hover);
  }

  @media (max-width: ${Variables.Breakpoints.md}) {
    font-size: 1.2rem;
    }

  :hover{
      background-color: var(--medium-teal-hover);
      color: white;
  }
 `

const ButtonLinkLarge: React.FC<ButtonProps> = ({ text, href, isPrimary, icon, style }) => {

    return (
        <ButtonLinkElement style={style} to={href} className={isPrimary ? "primary" : "secondary"}>{icon}{text}</ButtonLinkElement>
    );
};

export default ButtonLinkLarge;