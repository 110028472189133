import { Db } from '../services/Db';
import { useLocation, useParams } from 'react-router-dom';
import BookResults from '../components/search/BookResults';
import MainContainer from '../components/MainContainer';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { HelmetProvider, Helmet } from 'react-helmet-async';

interface ContainerProps {};

const BooksIncludingName: React.FC<ContainerProps> = () => {

  const db = new Db();
  const location = useLocation();
  const pathParts = location.pathname.split('/'); // Split the pathname into an array

  // Get the last part of the pathname
  const name = pathParts[pathParts.length - 1];
  var title = `Baby books including the name ${name} | Is Baby in a Book?`;
  var desc = `Is Baby in a Book helps you find books that contain ${name} as characters`;

  return (
    <HelmetProvider>
<div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />

         {/* Open Graph */}
         <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:image" content="https://isbabyinabook.co.uk/facebook.jpg" />
        <meta property="og:url" content={`https://isbabyinabook.co.uk${location.pathname}`} />
        <meta property="og:type" content="website" />

        {/* Twitter */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:image" content="https://isbabyinabook.co.uk/twitter.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <Header hasBanner={true}></Header>
      <div style={{background: "linear-gradient(to bottom, #00000026 0%,rgba(0,0,0,0) 100px)", paddingBottom: 50}}>
        <MainContainer>
          <BookResults></BookResults>
          
        </MainContainer>
      </div>
        
        <Footer></Footer>
    </div>
    </HelmetProvider>
    
  );
};

export default BooksIncludingName;
