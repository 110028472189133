import SimilarNames from "./SimilarNames";

interface ContainerProps {
    name?: string
 }

const NoResults: React.FC<ContainerProps> = ({name}) => {

    return (
        <div>
            <h1>We couldn't find any books with a character called {name}</h1>

            <SimilarNames name={name} title="How about trying a similar name?"></SimilarNames>
        </div>        
    );
};

export default NoResults;