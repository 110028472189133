import styled from "styled-components";

import SubmitForm from "../SubmitForm";

import { IoPersonAdd } from "react-icons/io5";
import MainContainer from "../MainContainer";

const Widget = styled.div`
  background: var(--light-teal);
  background: linear-gradient(
    45deg,
    var(--light-teal) 0%,
    #ddd 50%,
    var(--light-teal) 100%
  );
  padding: 40px 0;
`;

const AddBookWrapper = styled.div`
  background: #fff;
  border-radius: 5px;
  border: 5px solid var(--medium-teal);
`;

const ContactUsDiv = styled.div`
  margin: 0;
  padding: 40px;
  background: var(--light-teal);
  background: linear-gradient(to bottom, #00000015 0%,rgba(0,0,0,0) 20px), linear-gradient(var(--light-teal), var(--light-teal));

  h2{
    font-family: var(--slab-font);
    font-size: 2.4rem;
      margin: 0 auto 20px;
      color: var(--dark-teal);
  }

  h3{
    font-family: var(--slab-font);
    font-size: 2rem;
      margin: 40px auto 20px;
      color: var(--dark-teal);
  }

  p{
    font-size: 1.1rem;

    a{
        color: var(--dark-teal);
        font-weight: bold;
    }
  }

  .icon{
    color: var(--dark-teal);
  }
`;

interface ContainerProps {
    name?: string,
    title?: string,
    allNames?: string[]
}

const AddBook: React.FC<ContainerProps> = () => {

    return (
        <Widget>
            <MainContainer>
                <AddBookWrapper>

                    <ContactUsDiv>
                        <div className="icon"><IoPersonAdd fontSize={"2.4rem"} /></div>
                        <h2>Are we missing a book?</h2>

                        <p>We try our best to find every book we can, but there are still plenty that have not yet made our library.</p>
                        <p>Are you an author releasing a new book? Do you have a favourite book you read to your kids that we don't have? Have we missed your kids names in a book you've read? Please let us know!</p>
                        <p>Use the form below or send us an email at <a href="mailto:isbabyinabook@gmail.com">isbabyinabook@gmail.com</a></p>
                        <p>We check all suggestions, so please ensure that all recommendations are picture books aimed at children less than 6 years old.</p>

                        <h3>Request form</h3>

                        <SubmitForm key={`home-form`}></SubmitForm>
                    </ContactUsDiv>

                </AddBookWrapper>
            </MainContainer>
        </Widget>
    );
};

export default AddBook;