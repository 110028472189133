import { IoSearch } from "react-icons/io5";
import styled from "styled-components";

const ButtonElement = styled.button`
  position: relative;
  display: inline-block;
  padding: 20px 50px 20px 20px;
  padding: 20px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  border: none;
  text-decoration: none;
  font-size: 1.25rem;
  font-family: var(--slab-font);


  background-color: var(--dark-teal);
    color: white;
    text-shadow: 0 0 2px var(--dark-teal-hover);

    display: inline-flex;
  align-items: center;
  justify-content: center;

  :hover{
      background-color: var(--dark-teal-hover);
      color: white;
      cursor: pointer;
  }
 `

const ScrollToTopButton: React.FC = () => {
  const scrollToTopAndFocusInput = () => {
    // Scroll to top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
    document.getElementById("HeaderSearch")?.focus();
  };

  return (
    <div>
      <ButtonElement onClick={scrollToTopAndFocusInput}> Search for a name <IoSearch style={{marginLeft: 10}}></IoSearch></ButtonElement>
    </div>
  );
};

export default ScrollToTopButton;