import { useEffect, useState } from "react";
import { Db } from "../../services/Db";

import styled from "styled-components";

import { useParams } from 'react-router';
import ButtonLinkLarge from "../ButtonLinkLarge";
import { SimilarNamesService } from "../../services/SimilarNamesService";
import SubmitForm from "../SubmitForm";


import { IoPersonAdd, IoSearch, IoStar } from "react-icons/io5";
import Name from "../atoms/Name";
import PersonalisedBookAd from "../ads/PersonalisedBookAd";

const SimilarNamesWrapper = styled.div`
  background: #fff;
  border-radius: 5px;
  border: 5px solid var(--medium-teal);
`;

const SimilarNamesDiv = styled.div`
  margin: 0;
  padding: 40px;

  h2{
    font-size: 2rem;
    font-family: var(--slab-font);
      margin: 0 auto 5px;
      color: var(--dark-teal);
  }

  h3{
      margin: 0 auto 10px;
      color: var(--dark-teal);
  }

  div{
      color: var(--dark-teal);
  }
`;

const ContactUsDiv = styled.div`
  margin: 0;
  padding: 40px;
  background: var(--light-teal);
  background: linear-gradient(to bottom, #00000015 0%,rgba(0,0,0,0) 20px), linear-gradient(var(--light-teal), var(--light-teal));

  h2{
    font-size: 2rem;
    font-family: var(--slab-font);
      margin: 0 auto 20px;
      color: var(--dark-teal);
  }

  p{
        a{
            color: var(--dark-teal);
            font-weight: bold;
        }
    }

  .icon{
    color: var(--dark-teal);
  }
`;

interface ContainerProps {
    name?: string,
    title?: string,
    allNames?: string[]
}

const SimilarNames: React.FC<ContainerProps> = ({ name, title, allNames }) => {

    const [currentName, setCurrentName] = useState<string>(name || "");
    const [similarNames, setSimilarNames] = useState<Array<string>>([]);
    const [similarAdditionalNames, setSimilarAdditionalNames] = useState<Array<string>>([]);
    const db = new Db();
    const similarNameService = new SimilarNamesService(db);

    let { slug } = useParams<{ slug: string }>();

    useEffect(() => {

        const init = async () => {

            if (!currentName && slug) {
                setCurrentName(slug);
            }

            if (currentName) {

                let similar = await similarNameService.getSimilarNames(currentName);

                if (similar) {
                    setSimilarNames(similar.manualNames);
                    setSimilarAdditionalNames(similar.aiNames);
                }
            }
        }

        init();

    }, [slug, name, allNames]);

    const createAiLink = (name: string) => {
        if (!name) {
            return null;
        }

        return (<ButtonLinkLarge style={{ "marginTop": "20px" }} key={name} href={"/books-including-name/" + name} text={name}></ButtonLinkLarge>);
    }

    var similarNameLinks = similarNames.length ? similarNames.map(s => (<ButtonLinkLarge style={{ "marginTop": "20px" }} key={s} href={"/books-including-name/" + s} text={s} isPrimary={true} icon={<IoStar fontSize="1rem" style={{ marginRight: "5px" }} />}></ButtonLinkLarge>)) : null;
    var similarAdditionalNameLinks = similarAdditionalNames.length ? similarAdditionalNames.map(s => createAiLink(s)) : null;

    if (similarNameLinks == null && similarAdditionalNameLinks == null) {
        return <></>;
    }

    return (
        <SimilarNamesWrapper>
            <SimilarNamesDiv>
                <div><IoSearch fontSize={"2.4rem"} /></div>
                <h2>Still want to find more books?</h2>
                <h3>How about trying a similar name?</h3>

                {similarNameLinks}{similarAdditionalNameLinks}
            </SimilarNamesDiv>

            {/* <PersonalisedBookAd></PersonalisedBookAd> */}

            <ContactUsDiv>
                <div className="icon"><IoPersonAdd fontSize={"2.4rem"} /></div>
                <h2>Are we missing a book with <Name name={name} /> in?</h2>
                <p>Use the form below or send us an email at <a href="mailto:isbabyinabook@gmail.com">isbabyinabook@gmail.com</a></p>
                <p>We check all suggestions, so please ensure that all recommendations are picture books aimed at children less than 6 years old.</p>

                <SubmitForm key={`${slug}-form`}></SubmitForm>
            </ContactUsDiv>

        </SimilarNamesWrapper>
    );
};

export default SimilarNames;