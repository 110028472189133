import styled from 'styled-components';
import { Variables } from '../variables';

const Container = styled('div')`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: ${Variables.Breakpoints.md}) {
        width: 640px;
        max-width: 640px;
    }

    @media (min-width: ${Variables.Breakpoints.lg}) {
        width: 960px;
        max-width: 960px;
    }
`

interface ContainerProps {
    children: any
}

const MainContainer: React.FC<ContainerProps> = ({ children }) => {
    return (
        <Container>
            {children}
        </Container>
    );
};

export default MainContainer;
