import styled from "styled-components";
import { Variables } from '../variables';
import us from '../assets/images/us.png';
import OneTwoThreeFooter from "./OneTwoThreeFooter";
import headerLogo from '../assets/images/logo.png';

// Header component
const SubFooter = styled.footer`
  display: block;
  position: relative;
  min-height: 30px;
  background: var(--dark-teal);
  background: linear-gradient(
    45deg,
    rgba(87, 204, 153, 1) 0%,
    rgba(56, 163, 165, 1) 14%,
    rgba(56, 163, 165, 1) 69%,
    rgba(87, 204, 153, 1) 100%
  );

  .us{
    position: relative;
    width: 700px;
    max-width: 90%;
    margin: 0 auto;
    padding: 40px 0;

    .inner{
      border-radius: 10px;
      background-color: var(--light-teal);
      margin: 20px;
      padding: 20px;

      h3{
        font-family: var(--slab-font);
        font-size: 1.8rem;
      }

      img{
        max-width: 150px;
        max-height: 150px;
      }
    }
  }
`;

const BlueFooter = styled.footer`
  display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  position: relative; 
  min-height: 300px;
  background: var(--dark-teal);
  padding: 40px 20px;

.flex{
  display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  position: relative; 

  >div{
    
  }
}

  .us{
    width: 60%;
    margin: 0 auto;

    .inner{
      border-radius: 10px;
      background-color: var(--light-teal);
      margin: 20px;
      padding: 20px;
    }
  }
`;

const Copyright = styled.div`
width: 100%;
color: var(--light-teal);
  text-align: center;
  padding-top: 20px;
`

const Logo = styled.div`
  display: block;
  text-align: center;
  box-sizing: border-box;
`;

const LogoLink = styled.a`
  display: block;
  width: 150px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${Variables.Breakpoints.md}) {
    width: 150px;
  }

  @media (min-width: ${Variables.Breakpoints.lg}) {
    width: 150px;
  }
`;

const LogoImage = styled.img`
  display: block;
  max-width: 100%;
  max-height: 150px;
`;

interface ContainerProps { }

const Footer: React.FC<ContainerProps> = () => {
const year = new Date().getFullYear();

  return (
    <div>
      <SubFooter>
      <div className="us">
              <div className="inner">
                <img src={us} />
                <h3>Built by Sam &amp; Soraya</h3>
                <p>In 2022 we welcomed our little twins into the world. Since then we have been building up our home library full of baby books to try and give our boys the best start.</p>
                <p>While books with characters called Sam are found in bookstores and charity shops throughout the land, we struggled to find any books with Soraya, Jasper, or Xander.</p>
                <p>This gave Soraya the idea to compile a list of books for new parents and then Sam decided to turn it in to a website.</p>
              </div>
            </div>
            <Logo className='footer-logo'>
              <LogoLink href='/home'><LogoImage src={headerLogo} alt="Is baby in a book header logo" title='Is baby in a book?' /></LogoLink>
            </Logo>
      </SubFooter>
      <BlueFooter>
        {/* <MainContainer> */}
          <OneTwoThreeFooter></OneTwoThreeFooter>
          <Copyright>&copy; IsBabyInABook 2022-{year}</Copyright>

          <div className="flexa">
            {/* <div className="us">
              <div className="inner">
                <img src={us} />
                <h3>Built by Sam &amp; Soraya</h3>
                <p>In 2022 we welcomed our little twins into the world. Since then we have been building up our home library full of baby books to try and give our boys the best start.</p>
                <p>While books with characters called Sam are found in bookstores and charity shops throughout the land, we struggled to find any boooks with Soraya, Jasper, or Xander.</p>
                <p>This gave Soraya the idea to compile a list of books for new parents and then Sam decided to turn it in to a website.</p>
              </div>
            </div> */}
            {/* <div className="disclaimers">
              <div className="inner">
                <h3>Do we make money from this?</h3>
                <p>We do have affiliate accounts with partners that will reward us in cash or vouchers for people that purchase books from our links.</p>
                <p>You don't pay a penny more though, in fact on some websites you may get a discount!</p>
                <p>When you purchase books using our links, this money gets used by us to buy more books for the boys.</p>
              </div>

              <div className="inner">
                <h3>Have you read every book?</h3>
                <p>No, we don't necessarily read every book. We use a variety of methods to populate our database.</p>
                <p>We can't guarantee that the content within the books are appropriate (or even if they are any good).</p>
                <p>If you aren't happy with the book, we can't give you a refund, we can remove from our database though if you contact us.</p>
              </div>
            </div> */}
          </div>
        {/* </MainContainer> */}

      </BlueFooter>
    </div>

  );
};

export default Footer;
