import { createClient } from "@supabase/supabase-js";

export interface Book {
    amazon_link_uk: string | null
    amazon_link_us: string | null
    id: string
    image_url: string | null
    summary: string | null
    title: string | null
    authors: string[] | null;
}

export interface SuggestionsDatabase {
    public: {
      Functions: {
        add_book: {
          Args: {
            param_names: string,
            params_book_title: string,
            params_author_name: string
          },
          Returns: undefined
        }
      }
    }
  }

export class SuggestionsDb {
    getClient = () => {
        const supabase = createClient<SuggestionsDatabase>('https://xzfsczkjsnufgddcjpvc.supabase.co',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inh6ZnNjemtqc251ZmdkZGNqcHZjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODIzNTQzMTEsImV4cCI6MTk5NzkzMDMxMX0.-JSHN4UqE9pghpz2xzSLtDvn6gYf5NKysu4-lme3roQ');

        return supabase;
    }

    createSuggestion = async (names: string, title: string, author: string): Promise<boolean> => {

        const supabase = this.getClient();

        let { data, error } = await supabase
            .rpc('add_book', {
                "param_names": names,
                "params_book_title": title,
                "params_author_name": author
            })

        if (error) {
            console.error("createSuggestion - error", error);
            return false;
        }
        else {
            return true;
        }
    }
}