import styled from 'styled-components';
import { Variables } from '../variables';

const Container = styled('div')`
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    justify-content: space-between;

    @media (min-width: ${Variables.Breakpoints.md}) {
        width: 640px;
        max-width: 640px;
    }

    @media (min-width: ${Variables.Breakpoints.lg}) {
        width: 960px;
        max-width: 960px;
    }

    @media (min-width: ${Variables.Breakpoints.xl}) {
        width: 1140px;
        max-width: 1140px;
    }

    >div{
        width: 100%;
        position: relative;
        box-sizing: border-box;
        margin-top: 20px;
        border-radius: 5px;

        h3{
            margin-top: 0px;
            margin-bottom: 0;
            padding: 30px;
            position: relative;
            background: var(--medium-teal);
            color: white;
            font-family: var(--slab-font);
            font-size: 1.1rem;
            letter-spacing: 0.5px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-left-radius: 100% 50px;
            border-bottom-right-radius: 100% 50px;

            @media (min-width: ${Variables.Breakpoints.lg}) {
                letter-spacing: 0.75px;
                font-size: 1.2rem;
            }
        }

        >div{
            background: linear-gradient( var(--light-teal) , white 20%);
            background: var(--light-teal);
            margin-top: -30px;
            padding: 35px 10px 10px;
            color: var(--dark-teal);
            border-radius: 10px;
            font-size: 1rem;

            @media (min-width: ${Variables.Breakpoints.lg}) {
                font-size: 1rem;
                padding: 45px 20px 20px;
            }

            a{
                color: var(--dark-teal);
            }
        }

        .number{
            width: 50px;
            height: 50px;
            line-height: 50px;
            vertical-align: middle;
            position: absolute;
            display: inline-block;
            left: 15px;
            top:0;
            z-index: 1;
            background: var(--dark-teal);
            background-color: var(--dark-teal);
            color: white;
            font-weight: bold;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        @media (min-width: ${Variables.Breakpoints.md}) {
            width: 30%;
            max-width: 30%;
        }
    }
`

interface ContainerProps {
    children?: any
}

const OneTwoThreeFooter: React.FC<ContainerProps> = ({ children }) => {
    return (
        <Container>
            <div>
                <h3>Do we make money from this?</h3>
                <div>
                    <p>We do have affiliate accounts with partners that will reward us in cash or vouchers when people purchase books using our links.</p>
                    <p>You don't pay a penny more though, in fact on some websites you may get a discount!</p>
                    <p>When you purchase books using our links, this money gets used by us to buy more books for the boys.</p>
                </div>
            </div>
            <div>
                <h3>Have we read every book?</h3>
                <div>
                    <p>No, we don't necessarily read every book. We use a variety of methods to populate our database.</p>
                    <p>We can't guarantee that the content within the books are appropriate (or even if they are any good).</p>
                    <p>If you aren't happy with the book, we can't give you a refund, we can remove from our database though if you contact us.</p>
                </div>
            </div>
            <div>
                <h3>Contact us</h3>
                <div>
                    <p>Are you an author releasing a new book?</p>
                    <p>Do you have a favourite book you read to your kids that we don't have?</p>
                    <p>Have we missed your kids names in a book you've read?</p>
                    <p>Please let us know! Send us an email at <a href="mailto:isbabyinabook@gmail.com">isbabyinabook@gmail.com</a></p>
                </div>
            </div>
        </Container>
    );
};

export default OneTwoThreeFooter;
