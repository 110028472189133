import { HelmetProvider, Helmet } from 'react-helmet-async';

import Header from '../components/Header';
import Footer from '../components/Footer';
import OneTwoThree from '../components/OneTwoThree';
import ScrollToTopButton from '../components/atoms/ScrollToTopButton';
import LatestBook from '../components/widgets/LatestBook';
import AddBook from '../components/widgets/AddBook';

const Home: React.FC = () => {
  var title = `Is Baby in a Book?`;
  var desc = `Is Baby in a Book helps you find books that contain your children as book characters`;

  return (
    <HelmetProvider>
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />

         {/* Open Graph */}
         <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:image" content="https://isbabyinabook.co.uk/facebook.jpg" />
        <meta property="og:url" content={`https://isbabyinabook.co.uk`} />
        <meta property="og:type" content="website" />

        {/* Twitter */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:image" content="https://isbabyinabook.co.uk/twitter.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      
      <Header>
        <OneTwoThree></OneTwoThree>
        <ScrollToTopButton></ScrollToTopButton>
      </Header>
      <LatestBook></LatestBook>
      
        <AddBook></AddBook>
      <Footer></Footer>
    </div>
    </HelmetProvider>
    
  );
};

export default Home;
