import styled from 'styled-components';
import { Variables } from '../variables';

import booksImage from '../assets/images/books-small.jpg';
import booksCoffeeImage from '../assets/images/books-coffee-small.jpg';
import childImage from '../assets/images/child-reading-small.jpg';

const Container = styled('div')`
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    justify-content: space-between;

    @media (min-width: ${Variables.Breakpoints.md}) {
        width: 640px;
        max-width: 640px;
    }

    @media (min-width: ${Variables.Breakpoints.lg}) {
        width: 960px;
        max-width: 960px;
    }

    @media (min-width: ${Variables.Breakpoints.xl}) {
        width: 1140px;
        max-width: 1140px;
    }

    div{
        width: 100%;
        position: relative;
        box-sizing: border-box;
        margin-top: 20px;
        border-radius: 5px;

        img{
            display: block;
            width: 100%;
            max-width: 100%;
            border-radius: 10px 10px 0 0 ;
            border-top: solid 5px var(--dark-teal);
        }

        h3{
            margin-top: 0px;
            margin-bottom: 0;
            padding: 20px;
            position: relative;
            border-bottom-left-radius: 100% 50px;
            border-bottom-right-radius: 100% 50px;
            background: var(--dark-teal);
            color: white;
            font-family: var(--slab-font);
            font-size: 1.2rem;
            letter-spacing: 0.5px;
            margin-top: -15px;
            padding: 40px;
            clip-path: polygon(0% 0%, 50% calc(10px), 100% 0%, 100% calc(100% - 10px), 50% 100%, 0% calc(100% - 10px));
            border-radius: 0;

            @media (min-width: ${Variables.Breakpoints.lg}) {
                letter-spacing: 0.75px;
                font-size: 1.4rem;
            }
        }

        p{
            background: linear-gradient( var(--light-teal) , white 20%);
            background: var(--light-teal);
            margin-top: -25px;
            padding: 35px 10px 10px;
            color: var(--dark-teal);
            border-radius: 10px;
            font-weight: 600;
            font-size: 1.1rem;

            @media (min-width: ${Variables.Breakpoints.lg}) {
                font-size: 1.2rem;
                padding: 45px 20px 20px;
            }
        }

        .number{
            width: 50px;
            height: 50px;
            line-height: 50px;
            vertical-align: middle;
            position: absolute;
            display: inline-block;
            left: 15px;
            top:0;
            z-index: 1;
            background: var(--dark-teal);
            background-color: var(--dark-teal);
            color: white;
            font-weight: bold;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        @media (min-width: ${Variables.Breakpoints.md}) {
            width: 30%;
            max-width: 30%;
        }
    }
`

interface ContainerProps {
    children?: any
}

const OneTwoThree: React.FC<ContainerProps> = ({ children }) => {


    return (
        <Container>
            <div>
                <img alt='Lots of books that need searching through' src={booksImage} />
                <span className='number'>1.</span>
                <h3>We search through over 1000 baby, toddler, and early readers books</h3>
                <p>
                    We don't just look at titles, we dig deeper to find characters you won't find with a simple search.
                </p>
            </div>
            <div>
                <img alt='Stack of books with a mug on top' src={booksCoffeeImage} />
                <span className='number'>2.</span>
                <h3>You find books with characters named like your friends and family</h3>
                <p>
                    You can then purchase these books online, direct from our partner sites.
                </p>
            </div>
            <div>
                <img alt='LA child and a parent reading a book together' src={childImage} />
                <span className='number'>3.</span>
                <h3>Children get to enjoy books with characters they can relate to</h3>
                <p>
                    With familiar names in the books you may find your kids are more excited about learning to read.
                </p>
            </div>
        </Container>
    );
};

export default OneTwoThree;
