import { createRef, useEffect, useState } from "react";

import styled from 'styled-components';
import ColorThief from "colorthief";
import React from "react";
import '../variables.css';
import { Variables } from '../variables';
import { Book } from "../services/Db";

const BookWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px 0;
    border: 10px solid white;
    border-radius: 5px;

    @media (min-width: ${Variables.Breakpoints.md}) {
        flex-direction: row;
        align-items: stretch;
    }
  `
const BookCover = styled.div`
 position:relative;
      width: 100%;

      div{
          padding: 20px;
          text-align: center;
      }

      img{
        position:relative;
          display: block;
          width: 100%;
          max-width: 100%;

          @media (max-width: ${Variables.Breakpoints.md}) {
            display: inline-block;
                max-height: 300px;
                width: auto;
            }
      }

      @media (min-width: ${Variables.Breakpoints.md}) {
        width: 50%;
    }
  `

const BookBack = styled.div`
display: flex;
align-items: center;
    justify-content: center;
width: 100%;

@media (min-width: ${Variables.Breakpoints.md}) {
        width: 50%;
    }
`

const BookDetailsWrapper = styled.div`
display: flex;
flex-direction: column;
text-align: center;

background: rgba(255, 255, 255, 0.75);
margin: 20px;
border-radius: 5px;

div{
    padding: 20px 20px 0;
}
`;

const BookTitle = styled.h2`
margin: 0;
font-size: 2rem;
font-family: var(--slab-font);
`;

const BookAuthor = styled.h3`
margin: 5px 0 10px;
font-size: 1.4rem;

a{
    color: black;

    :hover{
        color: var(--dark-teal);
    }
}
`;

const BookSummary = styled.p`
margin: 5px 0 5px;
font-size: 1rem;
`;

const BookNames = styled.p`
margin: 5px 0 5px;
font-size: 1rem;
font-weight: bold;
word-wrap: normal;

a{
    display: inline-block;
    margin-left: 5px;
    color: var(--dark-teal);
    

    :hover{
        color: var(--dark-teal-hover)
    }
}
`;

const BuyLink = styled.a`
    display: inline-flex;
  align-items: center;
    position: relative;
  margin-top: 10px;
  white-space: nowrap;
  background-color: var(--medium-teal);
  padding: 15px 28px 15px 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #ffffffcc;
  text-decoration: none;
  font-weight: bold;
  /* transition: 0.5s; */

  :hover{
      /* box-shadow: 0 0 5px #00000055;
      border: 2px solid #ffffffcc; */
      color: white;

      :after {
        right: 8px;
        }
    }

  :after {
    content: "";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #fff;
    }
`;


interface ContainerProps {
    book: Book
};

const ColouredBookRow: React.FC<ContainerProps> = ({ book }) => {
    const imgRef = createRef<HTMLImageElement>();

    const [colour, setColour] = useState<string>("");
    const [colour30, setColour30] = useState<string>("");
    const [colour60, setColour60] = useState<string>("");

    useEffect(() => {

    }, [colour]);

    const amazonUkLink = book.amazon_link_uk ? <BuyLink style={{ "background": `linear-gradient(to top, ${colour30} 0%, ${colour30} 100%), black` }} href={book.amazon_link_uk} target="_blank" rel="noopener noreferrer">Buy book online</BuyLink> : null;

    const namesElements = book.names?.length ? book.names.map((n) => <a key={`${book.id}-also-name-${n}`} href={"/books-including-name/" + n}>{n}</a>) : null;
    const namesSection = namesElements?.length ? <BookNames><span>Characters:</span> {namesElements}</BookNames> : null;

    const authorElements = book.authors?.length ? book.authors.map((n) => <a key={`${book.id}-author-${n}`} href={"/books-by-author/" + n}>{n}</a>) : null;

    return (
        <BookWrapper>
            <BookCover style={{ "background": `${colour}` }}>
                <div>

                    <img
                        crossOrigin={"anonymous"}
                        ref={imgRef}
                        src={book.image_url || ''}
                        alt={book.title || ''}
                        onLoad={() => {
                            const colorThief = new ColorThief();
                            const img = imgRef.current;
                            const result = colorThief.getColor(img);

                            let c = `rgb(${result[0]},${result[1]},${result[2]})`;
                            let c30 = `rgba(${result[0]},${result[1]},${result[2]},0.5)`;
                            let c60 = `rgba(${result[0]},${result[1]},${result[2]},0.66)`;
                            setColour(c);
                            setColour30(c30);
                            setColour60(c60);
                        }}
                    />
                </div>
            </BookCover>
            <BookBack style={{ "background": `${colour60}` }}>
                <BookDetailsWrapper>
                    <div>
                        <BookTitle>{book.title}</BookTitle>
                        <BookAuthor>{authorElements}</BookAuthor>
                        <BookSummary>{book.summary?.replaceAll("Read more", "").replaceAll("Read less", "")}</BookSummary>
                        {namesSection}
                    </div>

                    {amazonUkLink}
                </BookDetailsWrapper>

            </BookBack>

        </BookWrapper>
    );
};

export default ColouredBookRow;