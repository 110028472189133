import { useEffect, useState } from "react";
import { Book, Db } from "../../services/Db";

import styled from 'styled-components';

import '../../variables.css';
import ColouredBookRow from "../ColouredBookRow";
import MainContainer from "../MainContainer";

const MainWrapper = styled.div`
  padding: 20px;
`;

const MainTitle = styled.h1`
    text-align: center;
  font-family: var(--slab-font);
  font-size: 2.4rem;
  margin-bottom: 0;
`;

const SubTitle = styled.h2`
    text-align: center;
  font-size: 1.4rem;
  font-weight: normal;
  margin-top: 10px;
`;

const BookListWrapper = styled.div`
`;

interface ContainerProps {
};

const LatestBook: React.FC<ContainerProps> = ({ }) => {

    const [results, setResults] = useState<Array<Book>>([]);
    const [hasLoaded, setHasLoaded] = useState<boolean>(false);
    const db = new Db();

    useEffect(() => {

        const init = async () => {
            let apiResults = await db.getLatestBooks(2);

            setResults(apiResults.slice(0, 1));
            setHasLoaded(true);
        }
        init();

    }, []);

    const resultsList = () => {
        let items = results.map(book => {
            return (

                <div key={book.id}>
                    <div>
                        <ColouredBookRow book={book}></ColouredBookRow>
                    </div>
                </div>
            )
        });

        return (<BookListWrapper>
            {items}
        </BookListWrapper>);
    }

    if (!hasLoaded) {
        return <h1>Loading...</h1>
    }

    return (
        <MainContainer>
            <MainWrapper>
                <MainTitle>Latest additions</MainTitle>
                <SubTitle>We are always on the lookout for new books for as many children's names as possible. See the latest additions to our library.</SubTitle>
                {resultsList()}
            </MainWrapper>
        </MainContainer>
    );
};

export default LatestBook;