import styled from "styled-components";

interface ContainerProps {
    name?: string
 }

const NameSpan = styled.span`
  text-transform: uppercase;
  font-family: var(--slab-font);
`;

const Name: React.FC<ContainerProps> = ({name}) => {

    if(!name) return <></>;

    return (
        <NameSpan>{name}</NameSpan>    
    );
};

export default Name;