import { createClient } from "@supabase/supabase-js";

export interface Book {
    amazon_link_uk: string | null
    amazon_link_us: string | null
    id: string
    image_url: string | null
    summary: string | null
    title: string | null
    authors: string[] | null;
    names?: string[];
}

export interface Database {
    public: {
        Functions: {
            get_books_with_name: {
                Args: {
                    name_param: string
                },
                Returns: {
                    id: string,
                    name: string,
                    title: string,
                    summary: string,
                    image_url: string,
                    amazon_link_uk: string,
                    amazon_link_us: string,
                    names?: string
                }[]
            }
        }
    }
}

function groupBy<T, K extends keyof any>(items: T[], keySelector: (item: T) => K): Record<K, T[]> {
    const groups: Record<K, T[]> = {} as Record<K, T[]>;

    for (const item of items) {
        const key = keySelector(item);
        if (!groups[key]) {
            groups[key] = [];
        }
        groups[key].push(item);
    }

    return groups;
}

export class Db {

    static _names: string[] = [];

    getClient = () => {
        const supabase = createClient<Database>('https://knmqfszehggtpcozdfkt.supabase.co',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImtubXFmc3plaGdndHBjb3pkZmt0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2Nzk2ODI1OTcsImV4cCI6MTk5NTI1ODU5N30.jOGyN03ArdkgajXu9mQhQ6cbLfBY-vob04R4h75ZqIU');

        return supabase;
    }

    getAllNames = async (): Promise<string[]> => {

        if (Db._names.length) {
            return Db._names;
        }

        const supabase = this.getClient();

        const someNames = (await supabase.from('names').select('name').range(0, 999)).data?.map(x => x.name) as string[];
        const moreNames = (await supabase.from('names').select('name').range(1000, 1999)).data?.map(x => x.name) as string[];

        let allNames : string[] = [];
        allNames = allNames.concat(someNames);
        allNames = allNames.concat(moreNames);

        if (allNames && allNames.length > 500) {
            Db._names = allNames;

            return allNames;
        }

        return [];
    }

    getSimilarNames = async (name: string): Promise<string[]> => {
        const supabase = this.getClient();

        const query = `name_1.ilike.${name},name_2.ilike.${name}`;
        const allNames = (await supabase.from('similar_names').select('name_1, name_2').or(query)).data?.flatMap(x => [x.name_1 as string, x.name_2  as string]);

        if (allNames) {

            const lowerName = name.toLowerCase();
            return allNames.filter(x => x.toLowerCase() !== lowerName).filter((value, index, array) => array.indexOf(value) === index);
        }

        return [];
    }

    getLatestBooks = async (limit: number): Promise<Book[]> => {
        const supabase = this.getClient();

        let { data, error } = await supabase
            .rpc('get_latest_books', {
                "limit_param": limit
            })

        if (error) {
            console.error("getLatestBooks - error", error);
        }
        else {
            const rawResults = data;

            if (rawResults && rawResults.length) {

                var grouped = groupBy(rawResults, (r: { name: any; title: any; amazon_link_uk: any; amazon_link_us: any; id: any; image_url: any; summary: any; names: string; }) => r.title);

                const mapped: Book[] = [];

                for (const key in grouped) {
                    if (grouped.hasOwnProperty(key)) {
                        const bookRows = grouped[key];
                        const firstBookRow = bookRows[0];

                        mapped.push({
                            title: firstBookRow.title,
                            amazon_link_uk: firstBookRow.amazon_link_uk,
                            amazon_link_us: firstBookRow.amazon_link_us,
                            id: firstBookRow.id,
                            image_url: firstBookRow.image_url,
                            summary: firstBookRow.summary,
                            authors: bookRows.map(rr => rr.name),
                            names: firstBookRow.names?.split(",").filter((x) => x != null && x.length > 0)
                        })
                    }
                }

                return mapped;
            }
        }

        return [];
    }

    getBookWithName = async (name: string): Promise<Book[]> => {

        const supabase = this.getClient();

        let { data, error } = await supabase
            .rpc('get_books_with_name_2', {
                "name_param": name
            })

        if (error) {
            console.error("getBookWithName - error", error);
        }
        else {
            const rawResults = data;

            if (rawResults && rawResults.length) {

                var grouped = groupBy(rawResults, (r: { name: any; title: any; amazon_link_uk: any; amazon_link_us: any; id: any; image_url: any; summary: any; names: string; }) => r.title);

                const mapped: Book[] = [];

                for (const key in grouped) {
                    if (grouped.hasOwnProperty(key)) {
                        const bookRows = grouped[key];
                        const firstBookRow = bookRows[0];

                        mapped.push({
                            title: firstBookRow.title,
                            amazon_link_uk: firstBookRow.amazon_link_uk,
                            amazon_link_us: firstBookRow.amazon_link_us,
                            id: firstBookRow.id,
                            image_url: firstBookRow.image_url,
                            summary: firstBookRow.summary,
                            authors: bookRows.map(rr => rr.name),
                            names: firstBookRow.names?.split(",").filter((x) => x != null && x.length > 0)
                        })
                    }
                }

                return mapped;
            }
        }

        return [];
    }

    getBookForAuthor = async (name: string): Promise<Book[]> => {

        const supabase = this.getClient();

        let { data, error } = await supabase
            .rpc('get_books_for_author', {
                "name_param": name
            })

        if (error) {
            console.error("getBookWithName - error", error);
        }
        else {
            const rawResults = data;

            if (rawResults && rawResults.length) {
                return this.mapFromRawResults(rawResults);
            }
        }

        return [];
    }

    mapFromRawResults = (rawResults: { name: any; title: any; amazon_link_uk: any; amazon_link_us: any; id: any; image_url: any; summary: any; names: string; }[]) => {
        var grouped = groupBy(rawResults, (r: { name: any; title: any; amazon_link_uk: any; amazon_link_us: any; id: any; image_url: any; summary: any; names: string; }) => r.title);

        const mapped: Book[] = [];

        for (const key in grouped) {
            if (grouped.hasOwnProperty(key)) {
                const bookRows = grouped[key];
                const firstBookRow = bookRows[0];

                mapped.push({
                    title: firstBookRow.title,
                    amazon_link_uk: firstBookRow.amazon_link_uk,
                    amazon_link_us: firstBookRow.amazon_link_us,
                    id: firstBookRow.id,
                    image_url: firstBookRow.image_url,
                    summary: firstBookRow.summary,
                    authors: bookRows.map(rr => rr.name),
                    names: firstBookRow.names?.split(",").filter((x) => x != null && x.length > 0)
                })
            }
        }

        return mapped;
    }
}