import ReCAPTCHA from 'react-google-recaptcha';

import { useRef, useState } from "react";
import { Db } from "../services/Db";

import styled from "styled-components";
import '../variables.css'
import { Variables } from "../variables";
import { SuggestionsDb } from '../services/SuggestionsDb';
import Reaptcha from 'reaptcha';
import ButtonElement from './atoms/ButtonElement';
import { IoArrowDownCircle, IoCheckmarkCircle } from 'react-icons/io5';

const Thanks = styled('div')`
background-color: white;
margin: 40px auto 0;
width:60%;
padding: 20px;
font-size: 1.2rem;
border-radius: 10px;

>* {
    display: block;
    text-align: center;
}
`

const Form = styled('form')`
    padding: 0;
    margin: 0px 0 0;
    max-width: 100%;
    height: 0;
    overflow-y: hidden;
    transition: 0.5s;

    label{
        display: block;
        width: 100%;
        margin: 0 auto;

        @media (min-width: ${Variables.Breakpoints.md}) {
            width: 500px;
        }
    }

    span{
        display: block;
        font-weight: bold;
        color: var(--dark-teal);
        font-size: 1.4rem;
        text-align: left;
    }

    aside{
        color: var(--dark-teal);
        text-align: left;
        margin-bottom: 5px;
    }

    input{
        box-sizing: border-box;
        padding: 10px;
        border-radius: 5px;
        border:  2px solid var(--dark-teal);
        width: 100%;
        max-width: 100%;
        font-size: 1.2rem;

        ::placeholder {
            font-family: 'Comfortaa', cursive;
            font-size: 0.7em;
            text-transform: none;
        }

        @media (min-width: ${Variables.Breakpoints.md}) {
            width: 500px;

            ::placeholder {
                font-size: 0.7em;
            }
        }
    }

    .g-recaptcha{
        display: inline-block;
    }

    button{
        background-color: var(--dark-teal);
        color: white;
        border-radius: 5px;
        margin-top: 20px;
        padding: 10px;
        font-size: 1.2rem;
        font-weight: bold;
        border: none;
        transition: 0.25s;

        :disabled{
            opacity: 0.5;
        }

        :hover{
            cursor: pointer;
            background: var(--medium-teal-hover);
        }
    }
`

interface ContainerProps {
    key: React.Key
}

const SubmitForm: React.FC<ContainerProps> = (key) => {

    const db = new Db();
    const captchaRef = useRef<ReCAPTCHA>(null);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const [verified, setVerified] = useState<boolean>(false);
    const [valid, setValid] = useState<boolean>(false);
    const [formHeight, setFormHeight] = useState<string>('0');
    const [showButton, setShowButton] = useState<boolean>(true);

    const onVerify = () => {
        setVerified(true)
    };

    const [formData, setFormData] = useState<{ name: string; bookTitle: string; bookAuthor: string }>({
        name: '',
        bookTitle: '',
        bookAuthor: ''
    });

    const checkAndSetFormData = (model: { name: string; bookTitle: string; bookAuthor: string }) => {
        var isValid = !!(model.name && model.bookAuthor && model.bookTitle);
        setValid(isValid);

        setFormData(model);
    }

    const showForm = () => {
        setFormHeight('auto');
        setShowButton(false);
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // Check if reCAPTCHA has been verified
        if (verified) {
            try {
                // Send the form data to the API
                // alert("Save");
                var db = new SuggestionsDb();
                var result = await db.createSuggestion(formData.name, formData.bookTitle, formData.bookAuthor);

                if (result) {
                    setIsSubmitted(true);
                } else {
                    alert("Sorry, there was an error saving your submission.");
                }
            } catch (error) {
                console.error(error);

                alert("Sorry, there was an error saving your submission.");
            }
        } else {
            alert('Sorry, you were identified as a robot.');
        }
    };

    if (isSubmitted) {

        return (<div key={`${key}-submitted`}>
            <Thanks>
                <span><IoCheckmarkCircle fontSize={'2rem'} color={'var(--medium-teal)'}></IoCheckmarkCircle></span>
                <span>Thank you for helping us build our database of children's books.</span>
            </Thanks>
        </div>);
    }

    return (
        <div key={`${key}-form`}>
            {showButton ? <ButtonElement onClick={showForm}>Show form <IoArrowDownCircle></IoArrowDownCircle></ButtonElement> : null}
            
            <Form onSubmit={handleSubmit} style={{height: formHeight}}>
                <label>
                    <span>Character names</span>
                    <aside>You can separate multiple names with commas</aside>
                    <input
                        required={true}
                        type="text"
                        placeholder='Character names... e.g. Tom,Dick,Harry'
                        maxLength={100}
                        pattern="^[a-zA-Z]+(?:,[a-zA-Z]+)*$"
                        value={formData.name}
                        onChange={(event) =>
                            checkAndSetFormData({ ...formData, name: event.target.value })
                        }
                    />

                </label>
                <br />
                <label>
                    <span>Book title</span>
                    <input
                        required={true}
                        type="text"
                        placeholder='Book title'
                        maxLength={100}
                        value={formData.bookTitle}
                        onChange={(event) =>
                            checkAndSetFormData({ ...formData, bookTitle: event.target.value })
                        }
                    />
                </label>
                <br />
                <label>
                    <span>Book author</span>
                    <input
                        required={true}
                        type="text"
                        placeholder='Book author'
                        maxLength={100}
                        value={formData.bookAuthor}
                        onChange={(event) =>
                            checkAndSetFormData({ ...formData, bookAuthor: event.target.value })
                        }
                    />
                </label>
                <br />

                <div>
                    <Reaptcha size='compact' sitekey="6LcJQMMlAAAAAP-IR7W4OTsvIghbcaRBVU-qb_mD" onVerify={onVerify} />
                </div>

                <button type="submit" disabled={!(verified && valid)}>{((verified && valid) ? "Submit" : "Fill in fields to submit")}</button>
            </Form>
        </div>
    );
};

export default SubmitForm;