import styled from "styled-components";

const ButtonElement = styled.button`
  position: relative;
  padding: 10px;
  border-radius: 5px;
  border: none;
  text-decoration: none;
  font-size: 1.25rem;
  font-family: var(--slab-font);

  display: inline-flex;
  align-items: center;
  justify-content: center;

  background-color: var(--dark-teal);
    color: white;
    text-shadow: 0 0 2px var(--dark-teal-hover);

  :hover{
      background-color: var(--dark-teal-hover);
      color: white;
      cursor: pointer;
  }

  svg{
    margin-left: 10px;
  }
`;

export default ButtonElement;