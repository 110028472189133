import { useEffect, useState } from "react";
import { Book, Db } from "../../services/Db";

import styled from 'styled-components';

import { useParams } from 'react-router';
import NoResults from "./NoResults";

import '../../variables.css';
import ColouredBookRow from "../ColouredBookRow";
import AuthorSuggestions from "./AuthorSuggestions";
import { Variables } from "../../variables";

const MainTitle = styled.h1`
position: relative;
display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--heading-font);
  /* height:100px; */
  font-size: 1.4rem;
  background-color: var(--dark-teal);
  color: var(--light-teal);
  border-radius: 50px;
  /* clip-path: polygon(0% 0%, 50% 5%, 100% 0%, 97% 50%, 100% 100%, 50% 95%, 0% 100%, 3% 50%); */
  padding: 30px 10% 20px;
  /* margin-top: 0; */
  margin-top: -70px;
  z-index: 1;

  @media (max-width: ${Variables.Breakpoints.md}) {
    margin-top: -60px;
    font-size: 1.2rem;
    }

  span{
    display: block;
    font-family: var(--slab-font);
    font-size: 3rem;
    color: #fff;

    @media (max-width: ${Variables.Breakpoints.md}) {
    font-size: 2rem;
    }
  }
`;

const BookListWrapper = styled.div`
`;

interface ContainerProps {
};

const BooksByAuthorResults: React.FC<ContainerProps> = ({ }) => {

  const [results, setResults] = useState<Array<Book>>([]);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [hasResults, setHasResults] = useState<boolean>(false);
  const db = new Db();

  let { slug } = useParams<{ slug: string }>();

  useEffect(() => {

    const init = async () => {
      if (slug) {
        let apiResults = await db.getBookForAuthor(slug);

        setResults(apiResults);
        setHasLoaded(true);
        setHasResults(apiResults.length > 0);
      }
    }
    init();

  }, [slug]);

  const resultsList = () => {
    let items = results.map(book => {
      return (

        <div key={book.id}>
          <div>
            <ColouredBookRow book={book}></ColouredBookRow>
          </div>
        </div>
      )
    });

    return (<BookListWrapper>
      {items}
    </BookListWrapper>);
  }

  if (!hasLoaded) {
    return <h1>Loading...</h1>
  }

  if (!hasResults) {
    return <NoResults name={slug}></NoResults>
  }


  return (
    <div>
      <MainTitle>Books by the author <span>{slug?.toUpperCase()}</span></MainTitle>

      {resultsList()}

      <AuthorSuggestions></AuthorSuggestions>
    </div>
  );
};

export default BooksByAuthorResults;