import { useEffect, useState } from "react";
import { Db } from "../../services/Db";

import styled from "styled-components";

import { useParams } from 'react-router';
import SubmitForm from "../SubmitForm";


import { IoPersonAdd, IoSearch, IoStar } from "react-icons/io5";

const SimilarNamesWrapper = styled.div`
  background: #fff;
  border-radius: 5px;
  border: 5px solid var(--medium-teal);
`;

const ContactUsDiv = styled.div`
  margin: 0;
  padding: 40px;
  background: var(--light-teal);
  background: linear-gradient(to bottom, #00000015 0%,rgba(0,0,0,0) 20px), linear-gradient(var(--light-teal), var(--light-teal));

  h2{
    font-size: 2rem;
    font-family: var(--slab-font);
      margin: 0 auto 20px;
      color: var(--dark-teal);
  }

  p{
        a{
            color: var(--dark-teal);
            font-weight: bold;
        }
    }

  .icon{
    color: var(--dark-teal);
  }
`;

interface ContainerProps {
    name?: string,
    title?: string,
    allNames?: string[]
}

const AuthorSuggestions: React.FC<ContainerProps> = () => {
    let { slug } = useParams<{ slug: string }>();

    return (
        <SimilarNamesWrapper>
            <ContactUsDiv>
                <div className="icon"><IoPersonAdd fontSize={"2.4rem"} /></div>
                <h2>Are we missing any books or names?</h2>
                <p>Use the form below or send us an email at <a href="mailto:isbabyinabook@gmail.com">isbabyinabook@gmail.com</a></p>
                <p>We check all suggestions, so please ensure that all recommendations are picture books aimed at children less than 6 years old.</p>

                <SubmitForm key={`author-${slug}-form`}></SubmitForm>
            </ContactUsDiv>
        </SimilarNamesWrapper>
    );
};

export default AuthorSuggestions;