import { useEffect, useState } from "react";
import { Db } from "../../services/Db";

import { Link } from "react-router-dom";
import styled from "styled-components";
import '../../variables.css'
import { Variables } from "../../variables";

const NameListUl = styled('ul')`
    padding: 0;
    margin: 0px 0 0;
`

const NameListItem = styled('li')`
    list-style: none;
    margin: 0 20px;
    background: #ffffffdd;

    :first-child{
        padding-top: 10px;
    }
`

const NameListLink = styled(Link)`
    display: block;
    font-size: 1.2rem;
    padding: 10px;
    margin: 0 10px;
    border-radius: 5px;
    text-decoration: none;
    color: #002;
    font-weight: 600;

    :hover{
        color: #002;
        font-weight: bold;
        text-decoration: underline;
        background: #fff;
    }
`

const ArrowDown = styled('div')`
    margin: 0 20px;
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #ffffffdd;
  `

const SearchBoxWrapper = styled('div')`
    background-color: var(--dark-teal);
    padding: 6px;
    border-radius: 10px;
`

const SearchBox = styled("input")`
        padding-left: 30px;
        font-size: 1rem;
        text-align: center;
        width: 100%;
        max-width: 100%;
        padding: 15px 10px;
        border: none;
        border-radius: 5px;
        box-sizing: border-box;
        -webkit-padding-start: 30px;
        font-family: 'Zilla Slab', serif;
        font-weight: bold;
        text-transform: uppercase;
        outline: none;

    :focus {
        outline: 5px solid var(--light-teal);
        outline-offset: 6px;
    }

    ::placeholder {
        font-family: 'Comfortaa', cursive;
        font-size: 1em;
        text-transform: none;
    }

    @media (min-width: ${Variables.Breakpoints.md}) {
        font-size: 1.6rem;
        padding: 10px;

        ::placeholder {
            font-size: 0.7em;
        }
    }

    @media (min-width: ${Variables.Breakpoints.lg}) {
        font-size: 2rem;

        ::placeholder {
            font-size: 0.6em;
        }
    }

    

    ::-webkit-search-decoration {
        width:30px;
    }
`

interface ContainerProps { }

const NameSearch: React.FC<ContainerProps> = () => {

    const db = new Db();
    const [names, setNames] = useState<Array<string>>([]);
    const [name, setName] = useState<string>("");
    const [hideSuggestions, setHideSuggestions] = useState<boolean>(false);

    useEffect(() => {

        const init = async () => {
            var names = await db.getAllNames();

            setNames(names);
        }

        init();

    }, [])

    const handleClick = (event: any) => {

        if (event.target.innerText) {
            setName(event.target.innerText);
            setHideSuggestions(true);
        }
    };

    const handleInput = (event: any) => {
        setName(event.target.value);

        const hideSuggestions = name.length < 2;
        setHideSuggestions(hideSuggestions);
    }

    const suggestionsList = () => {

        if (!name || hideSuggestions) return <></>;

        let filtered = names.filter(n => n.toLowerCase().indexOf(name.toLowerCase()) > -1);

        let totalCount = filtered.length;

        let items = filtered.slice(0,5).map(n => {
            const linkUrl = "/books-including-name/" + n.toLowerCase();

            return <NameListItem key={n}>
                <NameListLink to={linkUrl} onClick={handleClick}>{n}</NameListLink>
            </NameListItem>
        });

        if(totalCount > 5){
            let moreCount = totalCount - 5;
            items.push(<NameListItem key="extras">+ {moreCount} other suggestion{moreCount > 1 ? "s" : ""}...</NameListItem>)
        }

        if(totalCount == 0){
            items.push(<NameListItem key="none">We have no books with any matching names.</NameListItem>)
        }

        if (!items) {
            return <></>;
        }

        return (
            <div>
                <NameListUl>{items}</NameListUl>
                <ArrowDown></ArrowDown>
            </div>

        );
    }

    return (
        <div>
            <SearchBoxWrapper className="green" style={{  }}>
                <SearchBox type="search"
                    id="HeaderSearch"
                    placeholder="Who do you want to find in a book?"
                    value={name}
                    onChange={handleInput} />
            </SearchBoxWrapper>

            <div>
                {suggestionsList()}

            </div>
        </div>
    );
};

export default NameSearch;
