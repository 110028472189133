import headerLogo from '../assets/images/logo.png';
import headerBackground from '../assets/images/header-30.jpg';
import NameSearch from './search/NameSearch';

import styled from "styled-components";
import MainContainer from './MainContainer';
import { Variables } from '../variables';

// Header component
const BlueHeader = styled.header`
  display: block;
  position: relative;
  background: rgba(56, 163, 165, 1);
  padding-bottom: 50px;
  border-bottom: 10px solid var(--dark-teal);
  background: url(${headerBackground}), linear-gradient(
    45deg,
    rgba(87, 204, 153, 1) 0%,
    rgba(56, 163, 165, 1) 14%,
    rgba(56, 163, 165, 1) 69%,
    rgba(87, 204, 153, 1) 100%
  );
  background-blend-mode: color-burn;
  background-repeat: no-repeat;
  background-position: center top;


  &.has-banner{
    padding-bottom: 100px;
  }
`;

// Logo component
const Logo = styled.div`
  padding-top: 50px;
  display: block;
  text-align: center;
  box-sizing: border-box;
`;

const LogoLink = styled.a`
  display: block;
  width: 200px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${Variables.Breakpoints.md}) {
    width: 200px;
  }

  @media (min-width: ${Variables.Breakpoints.lg}) {
    width: 200px;
  }
`;

const LogoImage = styled.img`
  display: block;
  max-width: 100%;
  max-height: 200px;
`;

const Search = styled.div`
  display: block;
  padding-bottom: 25px;
`;

const SearchContainer = styled(MainContainer)`
  border-radius: 5px;
`;

interface ContainerProps {
  children?: React.ReactNode;
  hasBanner?: boolean;
 }

const Header: React.FC<ContainerProps> = ({children, hasBanner}) => {
  return (
    <BlueHeader className={`main-header ${(hasBanner ? 'has-banner' : 'no-banner')}`}>
      <Logo className='header-logo'>
        <LogoLink href='/home'><LogoImage src={headerLogo} alt="Is baby in a book header logo" title='Is baby in a book?' /></LogoLink>
      </Logo>
      <Search className={`header-search ${(hasBanner ? 'has-banner' : 'no-banner')}`}>
        <SearchContainer>
          <NameSearch></NameSearch>
        </SearchContainer>
      </Search>

      {children}
    </BlueHeader>
  );
};

export default Header;
