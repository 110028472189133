import { Db } from "./Db";

export interface SimilarNames {
    manualNames: Array<string>;
    aiNames: Array<string>;
}

export class SimilarNamesService {

    /**
     *
     */
    constructor(private db: Db) {        
    }

    allNames: Array<string> = [];

    async cacheAllNames(){
        if(this.allNames.length){
            return;
        }

        this.allNames = await this.db.getAllNames();
    }

    async getSimilarNames(name: string) : Promise<SimilarNames>{

        const getPredictedSimilarNames = (name: string, names: string[], manualNames: string[]) => {
            if(!name || !names) return [];
    
            const foundNames = names.filter(n => name.toLowerCase().indexOf(n.toLowerCase()) > -1 || n.toLowerCase().indexOf(name.toLowerCase()) > -1 );
    
            const currentRemoved = foundNames.filter(n => n.toLowerCase() !== name.toLowerCase()).sort();

            if(manualNames.length < 1){
                return currentRemoved;
            }

            return currentRemoved.filter(n => manualNames.indexOf(n) == -1).sort();
        };

        await this.cacheAllNames();

        let manualNames = await this.db.getSimilarNames(name);
        let aiNames = getPredictedSimilarNames(name, this.allNames, manualNames);

        return {
            manualNames: manualNames,
            aiNames: aiNames
        }
    }

}