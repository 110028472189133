import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import './App.css';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import BooksIncludingName from './pages/BooksIncludingName';
import ScrollToTop from './components/ScrollToTop';
import BooksByAuthor from './pages/BooksByAuthor';

ReactGA.initialize('G-Z37YDHMD1J');

const RouteChangeTracker: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location.pathname]);

  return null;
};

function App() {
  useEffect(() => {
    // Track initial page view
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <RouteChangeTracker />
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/books-including-name'>
            <Route index element={<BooksIncludingName />} />
            <Route path=':slug' element={<BooksIncludingName />} />
          </Route>
          <Route path='/books-by-author'>
            <Route index element={<BooksByAuthor />} />
            <Route path=':slug' element={<BooksByAuthor />} />
          </Route>
          <Route path='*' element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
